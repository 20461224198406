<template>
  <div class="mod-config">
    <el-form :inline="true" :model="dataForm" @keyup.enter.native="getDataList()">
      <el-form-item>
        <el-input style="width:200px;margin-right: 20px;" v-model="dataForm.name" placeholder="班次名称" clearable>
        </el-input>
        <el-select style="width:150px;margin-right: 20px;" v-model="dataForm.state" placeholder="请选择状态" clearable>
          <el-option v-for="item in ztoptions" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
        <el-select style="width:150px;margin-right: 20px;" v-model="dataForm.shiftType" placeholder="班段类型" clearable>
          <el-option v-for="item in bdoptions" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button @click="getDataList()">查询</el-button>
        <el-button type="primary" @click="addOrUpdateHandle()">新增</el-button>
      </el-form-item>
    </el-form>
    <el-table :height="$store.state.app.client.height - 230" :data="dataList" v-loading="dataListLoading"
      style="width: 100%;">
      <el-table-column prop="name" header-align="center" align="center" label="班次名字">
      </el-table-column>
      <el-table-column prop="abbreviationName" header-align="center" align="center" label="班次简称">
        <template slot-scope="scope">
          <span :style="{ background: scope.row.color }" style="padding: 5px;color: #f9f9f9">{{
              scope.row.abbreviationName
          }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="businessType" header-align="center" align="center" label="业务类型">
        <template slot-scope="scope">
          <el-tag type="primary" size="small" effect="dark">
            {{ scope.row.businessType | dictFilter($store.state.dict.schedulingBusinessType) }}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column header-align="center" align="center" label="时间范围">
        <template slot-scope="scope">
          <el-tag :type="scope.row.startTimeIsCrossDay === '1' ? 'warning' : 'success'" size="mini" effect="dark">{{
              scope.row.startTimeIsCrossDay | dictFilter($store.state.dict.isCrossDay)
          }}</el-tag>
          <span style="padding-left: 3px">{{ scope.row.startTime }}</span>
          <span style="padding: 0 3px">-</span>
          <el-tag :type="scope.row.endTimeIsCrossDay === '1' ? 'warning' : 'success'" size="mini" effect="dark">{{
              scope.row.endTimeIsCrossDay | dictFilter($store.state.dict.isCrossDay)
          }}</el-tag>
          <span style="padding: 3px">{{ scope.row.endTime }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="shiftType" header-align="center" align="center" label="类型">
        <template slot-scope="scope">
          <el-tag type="primary" size="small" effect="dark">
            {{ scope.row.shiftType | dictFilter($store.state.dict.schedulingPeriodType) }}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="state" header-align="center" align="center" label="状态">
        <template slot-scope="scope">
          <!-- <el-popconfirm> -->
            <el-button v-if="scope.row.state === '1'" slot="reference" type="success" size="small" effect="dark">
              启用中
            </el-button>
            <el-button v-else type="danger" slot="reference" size="small" effect="dark">
              停用中
            </el-button>
          <!-- </el-popconfirm> -->
          <!-- <el-popconfirm title="你确定要这么做吗？" @confirm="changeStateHandle(scope.row)">
            <el-button v-if="scope.row.state === '1'" slot="reference" type="success" size="small" effect="dark">
              启用中
            </el-button>
            <el-button v-else type="danger" slot="reference" size="small" effect="dark">
              停用中
            </el-button>
          </el-popconfirm> -->

        </template>
      </el-table-column>
      <el-table-column fixed="left" header-align="center" align="center" width="150" label="操作">
        <template slot-scope="scope">
          <el-button v-if="scope.row.state == 1" type="text" size="small" @click="addOrUpdateHandle(scope.row.id)">编辑
          </el-button>
          <el-button v-if="scope.row.state == 0" style="color: rgb(64,158,255);" type="text" size="small"
            @click="changeStateHandle(scope.row)">启动
          </el-button>
          <el-button v-if="scope.row.state == 1" style="color: red;" type="text" size="small"
            @click="tablety(scope.row)">停用</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div style="display: flex; justify-content: right; padding-top: 10px">
      <el-pagination @size-change="sizeChangeHandle" @current-change="currentChangeHandle"
        :current-page="dataForm.pageNumber" :page-sizes="[10, 20, 50, 100]" :page-size="dataForm.pageSize"
        :total="dataForm.totalPage" layout="total, sizes, prev, pager, next, jumper">
      </el-pagination>
    </div>
    <!-- 弹窗, 新增 / 修改 -->
    <add-or-update v-if="addOrUpdateVisible" ref="addOrUpdate" @refreshDataList="getDataList"></add-or-update>
    <!-- 停用弹窗 -->
    <el-dialog title="提示" :visible.sync="tydialogVisible" width="30%" >
      <span>确定要停用【早班】吗？</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="tydialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="tycc">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import AddOrUpdate from './schedulingperiod-add-or-update'
import { querySchedulingPeriod, updateStateById } from "@/api/schedulingperiod/schedulingperiod";
export default {
  data() {
    return {
      dataForm: {
        name: '',
        pageNumber: 1,
        pageSize: 20,
        totalPage: 0,
        state:'',
        shiftType:''
      },
      dataList: [],
      tyrow: "",
      dataListLoading: false,
      dataListSelections: [],
      addOrUpdateVisible: false,
      tydialogVisible: false,
      ztoptions: [{
        value: '1',
        label: '启动'
      }, {
        value: '0',
        label: '停用'
      }
      ],
      ztvalue: '',
      bdoptions: [{
        value: '1',
        label: '业务班'
      }, {
        value: '2',
        label: '管理班'
      }
      ],
      bdvalue: ''

    }
  },
  components: {
    AddOrUpdate
  },
  mounted() {
    this.getDataList()
  },
  methods: {
    // 获取数据列表
    getDataList() {
      this.dataListLoading = true
      // let params = {...this.dataForm, pageNumber: this.pageNumber, pageSize: this.pageSize}
      querySchedulingPeriod({ params: this.dataForm }).then(res => {
        if (res.data.code === '200') {
          this.dataList = res.data.data.list
          this.dataForm.totalPage = res.data.data.totalRow
        } else {
          this.$message.error(res.data.message)
        }
        this.dataListLoading = false
      })
    },
    // 每页数
    sizeChangeHandle(val) {
      this.dataForm.pageSize = val
      this.dataForm.pageNumber = 1
      this.getDataList()
    },
    // 当前页
    currentChangeHandle(val) {
      this.dataForm.pageNumber = val
      this.getDataList()
    },
    // 新增 / 修改
    addOrUpdateHandle(id) {
      this.addOrUpdateVisible = true
      this.$nextTick(() => {
        this.$refs.addOrUpdate.init(id)
      })
    },
    // 删除
    deleteHandle(id) {
      const ids = id ? [id] : this.dataListSelections.map(item => {
        return item.id
      });
      this.$confirm(`确定对[id=${ids.join(',')}]进行[${id ? '删除' : '批量删除'}]操作?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
      })
    },
    changeStateHandle(row) {
      row.state = row.state === '1' ? '0' : '1'
      updateStateById(row.id, row.state).then(res => {
        if (res.data.code === '200') {
          this.$message.success(res.data.message)
          this.getDataList()
        } else {
          this.$message.error(res.data.message)
        }
      })
    },
    tablety(row) {
      this.tydialogVisible = true
      this.tyrow = row
    },
    // 停用
    tycc() {
      this.changeStateHandle(this.tyrow)
      this.tydialogVisible = false
    }
  }
}

</script>
<style  lang='scss' scoped>
/deep/ .el-form-item__content {
  display: flex;
}
</style>